<template>
    <form @submit.prevent="onSubmit">
        <div class="form-group">
            <label for="title" >Title</label>
            <input v-model.trim="validate.title.$model" @keyup="makeSlug" id="title" type="text" placeholder="Title"/>
        </div>
        <div class="form-group">
            <label for="slug">Slug</label>
            <input v-model.trim="validate.slug.$model" id="slug" type="text" placeholder="Slug"/>
        </div>
        <div class="form-group">
            <label for="categories">Categories</label>
            <input v-model.trim="validate.categories.$model" id="categories" type="text" placeholder="Categories"/>
        </div>

        <div class="form-group">
            <label for="date">Date</label>
            <input v-model.trim="validate.date.$model" id="date" type="date" placeholder="date"/>
        </div>

        <div class="form-group">
            <label for="url">URL</label>
            <input v-model.trim="validate.url.$model" id="url" type="text" placeholder="URL"/>
        </div>
        <div class="form-group">
            <label for="image">Image</label>
            <input id="image" @change="uploadImage" type="file" placeholder="Select Image"/>
        </div>
        <div class="form-group">
            <label for="content">Content</label>
            <textarea v-model.trim="validate.content.$model" id="content" placeholder="Text">

            </textarea>
        </div>
        <div class="form-submit">
            <input type="submit" value="Save">
        </div>
    </form>
</template>

<script>
    import axios from 'axios'
    import {reactive,ref,onMounted} from 'vue'
    import {useRouter} from 'vue-router'
    import {useValidate,getAuth} from '@/composables'
    import {required,minLength} from '@vuelidate/validators'
 
 
 export default {
        name: 'create',
        props: ['id'],
        setup(props){
            const router = useRouter()
            const currentProject = ref()
            const formData =  reactive({
                title: '',
                slug: '',
                categories:'',
                date:'',
                url:'',
                image:'',
                content:''
            })

            const rules = {
                title: {
                    required,
                    minLength: minLength(5)
                },
                slug: {
                    required,
                },
                categories: {},
                date: {},
                url: {},
                image: {},
                content: {}
            }            
            const error = ref(null)
            
            const {validate} = useValidate(rules,formData,error)

            const uploadImage = (e) => {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length) {
                    return;
                }
                formData.image = files[0]
            }

            const makeSlug = (e) => {
                let data = e.target.value
                data = data.toLowerCase()
                data = data.replaceAll(' ','-')
                formData.slug = data
            }

            const onSubmit = () => {
                console.log(formData)
                
                let fd = new FormData()
                Object.keys(formData).forEach((key) => {
                    fd.append(key,formData[key])
                })


                if (props.id){
                    axios.put(process.env.VUE_APP_BASE_URL+"/api/projects/"+props.id,fd,getAuth()).then((res) =>{
                        alert(res.data.msg)
                        router.push({name:'project.index'})
                    })
                    .catch((err) => {
                        let msg = ''
                        err.response.data.errors[0].errorMessage.details.forEach((er) => {
                            console.log(er.message)
                            msg += er.message+"\n"
                        })
                        alert(msg)
                    })
                }
                else{
                    axios.post(process.env.VUE_APP_BASE_URL+"/api/projects/save",fd,getAuth()).then((res) => {
                        alert(res.data.msg)
                        router.push({name:'project.index'})
                    }).catch((err) => {
                        let msg = ''
                        err.response.data.errors[0].errorMessage.details.forEach((er) => {
                            console.log(er.message)
                            msg += er.message+"\n"
                        })
                        alert(msg)
                    })
                }

            }

            onMounted(() => {
                if (props.id){
                    axios.get(process.env.VUE_APP_BASE_URL+"/api/projects/"+props.id,getAuth()).then((res) => {
                        currentProject.value = res.data
                        Object.keys(formData).forEach((key) => {
                            formData[key] = currentProject.value[key]
                        })
                        let d = new Date(currentProject.value['date'])
                        formData['date'] = (d.toISOString().split('T')[0])
                        console.log(formData)
                    })
                }
            })

            return {
                onSubmit, validate, uploadImage,makeSlug
            }
        }
    }
</script>

<style scoped>
    .form-group{
        margin-bottom: 10px;
    }
    .form-group label{
        display: block;
    }
    .form-group input{
        width: 70%;
    }
    .form-group textarea{
        width: 70%;
    }
</style>